<template>
  <div class="mb-4">
    <label class="block text-gray-700 text-sm font-bold mb-2" for="surname">
      {{ label }}
    </label>
    <input
      :value="value"
      @input="handleChange"
      required
      aria-required="true"
      :max="max"
      :min="min"
      class="
        shadow
        appearance-none
        border
        rounded
        w-full
        py-2
        px-3
        text-gray-700
        leading-tight
        focus:outline-none
        focus:shadow-outline
      "
      :type="type"
      :placeholder="placeholder"
    />
  </div>
</template>
<script>
/**
 * A wrapper component for the HTML5 input tag
 * @example
 * <input-ui
            label="Surname *"
            placeholder="Surname"
            :value="addEnrollee.value.lastname"
            @handleChange="handleChange($event, 'lastname', addEnrollee.value)"
          />
 */
export default {
  props: {
    value: {
      required: true,
      type: String,
    },
    type: {
      default: "text",
    },
    placeholder: String,
    label: String,
    max: String,
    min: String,
  },
  setup(props, { emit }) {
    const label = props.label;
    const type = props.type;
    const placeholder = props.placeholder;
    const handleChange = (e) => { // handles the change in the value and emits to the parent component
      emit("handleChange", e.target.value);
    };
    return {
      handleChange,
      label,
      type,
      placeholder,
    };
  },
};
</script>

<style scoped>
</style>
