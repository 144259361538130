<template>
  <div
    v-if="show.value"
    class="
      fixed
      join-it
      shadow-lg
      grid grid-cols-6
      gap-4
      w-100
      p-2
      m-2
      rounded-lg
      flex
      font-mono
      self-center
      bg-opacity-75
    "
    :class="getColor"
    style="z-index: 99; top: 4rem; right: 4rem"
  >
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="icon icon-tabler icon-tabler-info-circle"
        width="44"
        height="44"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="white"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <circle cx="12" cy="12" r="9" />
        <line x1="12" y1="8" x2="12.01" y2="8" />
        <polyline points="11 12 12 12 12 16 13 16" />
      </svg>
    </div>
    <div class="text-white items-center flex col-start-2 col-span-4">
      {{ message.value }}
    </div>
  </div>
</template>
<script>
/**
 * A shared component for creating notifications
 * @example
 * <notify-ui
        key="something"
        :type="notify.type"
        :message="notify.message"
        :showi="notify.show"
        timer="7000"
      />
 */
import { onMounted, computed, watch } from "@vue/runtime-core";
export default {
  props: {
    message: Object, //the message to display in the notification
    type: Object, // type of notification
    showi: { default: false }, // controls hiding/showing the notification
    timer: { // the timeout for for notifications
      type: String,
      default: 4000,
    },
  },
  setup(props) {
    let message = props.message;
    let type = props.type;
    let show = props.showi;
    let color = "bg-blue-500";
    let timer = props.timer;
    let getColor = computed(() => {
      if (type.value === "success") {
        return "bg-green-500";
      } else if (type.value === "failed") {
        return "bg-red-500";
      } else {
        return "bg-blue-500";
      }
    });

    watch(show, () => {
      setTimeout(() => {
        show.value = false;
      }, timer);
    });
    return {
      message,
      type,
      color,
      getColor,
      show,
    };
  },
};
</script>

<style scoped>
</style>