<template>
  <div class="w-full mx-auto slide-in">
    <notify-ui
      key="something"
      :type="notify.type"
      :message="notify.message"
      :showi="notify.show"
      timer="7000"
    />
    <div class="flex justify-center items-center">
      <div class="bg-white w-4/5 border border-gray-300 rounded">
        <div
          class="
            tracking-wide
            text-sm text-header-color
            px-8
            py-4
            font-semibold
          "
        >
          <div class="flex justify-start items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-lock"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#000000"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <rect x="5" y="11" width="14" height="10" rx="2" />
              <circle cx="12" cy="16" r="1" />
              <path d="M8 11v-4a4 4 0 0 1 8 0v4" /></svg
            >Change Password
          </div>
        </div>

        <div class="border-t border-gray-200" />
        <div class="pt-8 grid grid-cols-1 p-4">
          <input-ui
            label="Old Password"
            placeholder="Enter Old Password"
            type="password"
            :value="options.value.oldPassword"
            @handleChange="handleChange($event, 'oldPassword', options.value)"
          />
          <input-ui
            label="New Password"
            placeholder="Enter New Password"
            type="password"
            :value="options.value.newPassword"
            @handleChange="handleChange($event, 'newPassword', options.value)"
          />
          <input-ui
            label="Confirm New Password"
            placeholder="Confirm New Password"
            type="password"
            :value="options.value.cPassword"
            @handleChange="handleChange($event, 'cPassword', options.value)"
          />
          <button
            class="
              bg-blue-500
              hover:bg-blue-700
              text-white
              font-bold
              py-2
              px-4
              rounded
            "
            type="submit"
            @click="changePassword"
          >
            Change Password
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from "@vue/reactivity";
import InputUi from "../../../shared/ui/Input-ui.vue";
import NotifyUi from "../../../shared/ui/Notify-ui.vue";
import userService from "../../../services/user-service";
import { getCurrentInstance } from "@vue/runtime-core";
export default {
  components: { InputUi, NotifyUi },
  setup() {
    let instance = getCurrentInstance();
    const loading = instance.appContext.config.globalProperties;
    let options = reactive({
      value: {
        oldPassword: "",
        newPassword: "",
        cPassword: "",
      },
    });

    const notify = {
      message: reactive({ value: "" }),
      show: reactive({ value: false }),
      type: reactive({ value: "" }),
    };
    const changePassword = () => {
      let temp = {
        old_password: options.value.oldPassword,
        password: options.value.newPassword,
        confirm_password: options.value.cPassword,
      };
      let loader = loading.$loading.show();
      userService
        .resetPassword(temp)
        .then((data) => {
          loader.hide();
          notify.type.value = "success";
          notify.message.value = "Password Changed Succesfully";
          notify.show.value = true;
          Object.assign(
            options,
            reactive({
              value: {
                oldPassword: "",
                newPassword: "",
                cPassword: "",
              },
            })
          );
        })
        .catch((err) => {
          loader.hide();
          notify.type.value = "failed";
          notify.message.value = "Error Occured Password Change Failed";
          notify.show.value = true;
        });
    };
    const handleChange = async (payload, test = false, object) => {
      if (test) object[test] = payload;
    };
    return {
      notify,
      options,
      changePassword,
      handleChange,
    };
  },
};
</script>

<style scoped>
</style>
